<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
  <g clip-path="url(#clip0_2681_23502)">
    <path d="M10.3115 9.32C10.3115 10.2869 11.2376 10.6674 13.1546 11.2743C15.7392 12.128 16.3961 13.3829 16.3961 14.8126C16.3961 17.5177 13.7038 18.032 13.273 18.1246V19.4H11.1192V18.0731C10.4515 17.888 8.67456 17.2811 7.99609 15.0286L9.78379 14.3394C9.8484 14.5657 10.4084 16.4891 12.3684 16.4891C13.3699 16.4891 14.5007 15.9954 14.5007 14.8331C14.5007 13.8457 13.7469 13.3211 12.0453 12.7451C10.8607 12.3337 8.43763 11.6754 8.43763 9.34057C8.43763 9.23771 8.4484 6.872 11.1192 6.296V5H13.273V6.27543C15.1253 6.60457 15.8469 8.11657 16.0084 8.57943L14.3069 9.26857C14.1884 8.90857 13.6715 7.89029 12.2607 7.89029C11.5069 7.89029 10.3115 8.27086 10.3115 9.32Z" fill="#6366FA"/>
    <circle cx="11.9961" cy="12" r="11.25" stroke="#6366FA" stroke-width="1.5"/>
  </g>
  <defs>
    <clipPath id="clip0_2681_23502">
      <rect :width="size" :height="size" fill="white" transform="translate(-0.00390625)"/>
    </clipPath>
  </defs>
</svg>
</template>

<script>
export default {
  name: 'IcTargetBudget',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped></style>
